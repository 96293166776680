import React, { useState, useEffect } from 'react';
import '../../App.css';

const Countdown = () => {
    const calculateTimeLeft = () => {
        let endDate = new Date('September 15, 2024 00:00:00').getTime();
        let now = new Date().getTime();
        let difference = endDate - now;

        if (difference <= 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        let days = Math.floor(difference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [time, setTime] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    return (
        <div>
            <h4 className='py-2' style={{textAlign:'center', color:'#fff'}}>PreSale Starts In</h4>
            <div className='my-2 mt-4 lett' style={{display:'flex', justifyContent:'center'}}>
                <div>
                    <span className='fs-2 presale_btn dd'>{time.days}</span><br/>
                    <h5 className='text-center mt-1'>Day{time.days !== 1 ? 's' : ''}</h5>
                </div>

                <div>
                    <span className='fs-2 presale_btn dd'>{time.hours}</span><br/>
                    <h5 className='text-center mt-1'>Hour{time.hours !== 1 ? 's' : ''}</h5>
                </div>

                <div>
                    <span className='fs-2 presale_btn dd'>{time.minutes}</span><br/>
                    <h5 className='text-center mt-1'>Minute{time.minutes !== 1 ? 's' : ''}</h5>
                </div>

                <div>
                    <span className='fs-2 presale_btn dd'>{time.seconds}</span><br/>
                    <h5 className='text-center mt-1'>Second{time.seconds !== 1 ? 's' : ''}</h5>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
