import React, { useEffect, useState } from 'react';
import Header from '../Header/Header'; 
import Carousel from 'better-react-carousel';
import {BsArrowRightShort} from 'react-icons/bs';
import {BsTwitter} from 'react-icons/bs';
import {BsDiscord} from 'react-icons/bs';
import {FaTelegramPlane} from 'react-icons/fa';
import {FaRedditAlien} from 'react-icons/fa';
import {AiFillLinkedin} from 'react-icons/ai';
import {AiOutlineArrowDown} from 'react-icons/ai';
import {FaYoutube} from 'react-icons/fa';
import {BsMedium} from 'react-icons/bs'; 
import Investor from '../Investor/Investor';
import TokenSystem from '../../Comp/TokenSystem/TokenSystem';
import Roadmap from '../../Comp/Roadmap/Roadmap';
import WhitePaper from '../../Comp/WhitePaper/WhitePaper';
import Lkl from '../../Comp/img/Logg.jpg'
import S1 from '../../Comp/img/pinkswap.png'
import S2 from '../../Comp/img/bin.png'
import S3 from '../../Comp/img/SS3.png'
import S4 from '../../Comp/img/polychain.png'
import S10 from '../../Comp/img/dak (2).png'
import S55 from '../../Comp/img/coinbase.png'
import j1 from '../../Comp/img/trust.png'
import Attend from '../../Comp/img/logo-aiweb3.png'
import Attend1 from '../../Comp/img/ggg (1).png'
import Attend10 from '../../Comp/img/Logo20.png'
import j3 from '../../Comp/img/SS8.png'
import jai from '../../Comp/img/jj.png'
import j4 from '../../Comp/img/5irechain.png'
import j5 from '../../Comp/img/arbitrum.png'
import j6 from '../../Comp/img/SS11.png'
import j7 from '../../Comp/img/SS12.png'
import j8 from '../../Comp/img/SS13.png' 
import j9 from '../../Comp/img/SS14.png'   
import j19 from '../../Comp/img/chainlink.png'   
import j20 from '../../Comp/img/sui.png'   
import vv1 from '../../Comp/img/arbitrum.png'   
import vv2 from '../../Comp/img/chainlink.png'   
import vv3 from '../../Comp/img/5irechain.png'   
import 'animate.css';
import Career from '../Career/Career'; 
import Footer from '../Footer/Footer';
import {  NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import OurVision from '../OurVision/OurVision';
import Product from '../Products/Product';
import Generate from '../../ImageGenerate/Generate';
import Team from '../Team/Team'; 
import Staking from '../Stacking/Staking';
// import Chat from '../ChatBox/Chat'; 
import Lottie from 'react-lottie-player' 

import pesa from '../img/money.json'
import PinkSale from '../PinkSale/PinkSale';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../App.css'
import CounDown from './CounDown';
import Gallery from './Gallery';

import dubai from '../img/Singapore.jpg'
const Home = () => {
  
  const [show1, setShow1] = useState(false); 
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  useEffect(()=>{
   setTimeout(() => {
    handleShow1()
   }, 1500);
  },[])
  return (
    <div className='main'> 

    <div>
        <Header/>
       </div> 
<div>
 


 {/* preseal pop up */}


 <Modal show={show1} onHide={handleClose1} size="lg" style={{paddingTop:'100px', backgroundColor:'transparent' , backdropFilter:'blur(5px)' }}>
 <div  className='Mdl   shadowed-element' > 

        <Modal.Header closeButton   style={{border:'none'}}>
          <Modal.Title style={{opacity:'0'}}> Armor Ai</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{borderRadius:'15px'}}>
          
          <h2 className='text-center text-white' > Get AAI Token in Presale at $0.020  </h2>
          <h3 className='text-center text-white py-3' > Launching Price at $0.050  </h3>
 
         <CounDown/>

            <div className='my-4'  style={{display:'flex', justifyContent:'center'}}>
             <button class="Stack mt-2 text-rainbow-animation"  style={{backgroundColor:'white',   fontSize:'20px'}}> Comming Soon</button>
            </div>
        </Modal.Body>
        </div>
      </Modal>




</div>
       <section >
    <div className='container pd4' >
    <div className='row  '>
    <div className='col-lg-11 m-auto'>
      <div className='row '>

      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'> 
      <h1 className='hed animate__animated animate__bounce animate__delay-1.0s' style={{paddingTop:'150px',fontSize:'50px', color:'#fff'}}>Protecting <br/>
       <span className='Humanity  text-rainbow-animation'> Humanity  </span> From AI </h1>


      <p className="mt-5 hed1 animate__animated animate__bounce animate__delay-1.1s" style={{fontSize:'20px', color:'#fff', letterSpacing:'1px'}}>Developing world’s first Preventive AI, <br/>to battle AI if it becomes uncontrollable </p>


         <div>
        <Link to="Solution" smooth={true} duration = {100}>  <button type='btn' className='mt-4 animate__animated animate__bounce animate__delay-1.1s' style={{backgroundColor:'transparent',color:'#fff',   border:'1px solid #fff', fontSize:'22px', borderRadius:'30px', padding:'5px 5px 5px 45px'}}>Explore <AiOutlineArrowDown className='ms-3 p-1'  style={{background: 'linear-gradient(146.53deg, #FFFF25 2.81%, #FF55F3 44.51%, #53DBFF 82.08%, #FF00FF 123.02%)', borderRadius:'50%', fontSize:'40px',}}/></button>  </Link>
         </div>
     </div>

     <div className='col-lg-4 col-sm-12'>
        

     </div> 
     <div className='col-lg-2 col-sm-12 '>
          
     <div className='rot shadowed-element' style={{marginTop:"130px", lineHeight:'60px', 
     background: 'rgba(255, 255, 255, 0.04)', width:'60px', borderRadius:'30px',  
     backdropFilter:'blur(8px)'}}>
       

     <NavLink to='https://twitter.com/Armor_ai01'>
     <div className='pt-2' style={{display:'flex', justifyContent:'center'}}>
     <BsTwitter style={{backgroundColor:'#030614',color:'#53DBFF',
     zIndex:'999',   padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
     </div>
     </NavLink>
     
       <NavLink to='https://discord.gg/fNR9vvhs'>
       <div style={{display:'flex', justifyContent:'center'}}>
       <BsDiscord style={{backgroundColor:'#030614',color:'#5865F2',
       zIndex:'999',   padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
       </div>
       </NavLink>
   
       

        <NavLink to='https://t.me/armorai01'>
       <div style={{display:'flex', justifyContent:'center'}}>
       <FaTelegramPlane style={{backgroundColor:'#030614',color:'#53DBFF',
       zIndex:'999',   padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
       </div>
       </NavLink> 

       <NavLink to='https://www.reddit.com/user/ArmorAi'>
       <div style={{display:'flex', justifyContent:'center'}}>
       <FaRedditAlien style={{backgroundColor:'#030614',color:'#FF8514',
       zIndex:'999',   padding:'10px', fontSize:'42px', borderRadius:'15px'}} /><br/>
       </div>
       </NavLink>
   
       <NavLink to='https://www.linkedin.com/company/armor-ai/'>
       <div style={{display:'flex', justifyContent:'center'}}>
       <AiFillLinkedin style={{backgroundColor:'#030614',color:'#5865F2',
       zIndex:'999',   padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
       </div>
       </NavLink>
   
   
       <NavLink to="https://medium.com/@aiarmor42">
      <div style={{display:'flex', justifyContent:'center'}}>
     <BsMedium style={{backgroundColor:'#030614',color:'#fff',
     zIndex:'999',   padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
      </div>  
      </NavLink>
   
       <NavLink target='_blank' to='https://www.youtube.com/channel/UCUDtCqK3DRnrVj_02RjP8vA'>
       <div style={{display:'flex', justifyContent:'center'}}>
       <FaYoutube style={{backgroundColor:'#030614',color:'#ff0000',
       zIndex:'999',padding:'10px', fontSize:'38px', borderRadius:'15px'}} /><br/>
       </div> 
       </NavLink>

     </div>
     </div>
      </div>
    </div>
     
    </div>
   </div>
   </section>


   {/* Investor section start */} 
   <div className='Investtor'>
   <Investor/>
   </div>  
   
   {/* Investor section end */}



      {/* Token section start */}
   <div>
    <TokenSystem/>
   </div> 
      {/* Token section end*/} 


{/* White section */}
<div>
<WhitePaper/>
</div>

{/* White section end*/}

 
{/* Our Vision section start*/}
<div id='Solution' >
 <OurVision/> 
</div>

{/* Our Vision section end*/}

  
{/* Pink Sale section start */}
 
 {/* <PinkSale/>  */}

{/* Pink Sale section end */}



{/* Staking section start */}

<div className='container my-5'>
  <div className='row '>
  <h1 className=' text-center mt-5 animation-element slide-left testimonial' ><span className=' text-center text-rainbow-animation' style={{fontWeight:'600',letterSpacing:'1px'}}> Stake And Earn Massive Rewards </span></h1>
    <div className='col-lg-11 m-auto'>

      <div className='row'>

      <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
       
       <Lottie
            loop
            animationData={pesa}
            play
            style={{ width:'100%', height:'auto' }} />

       </div>
        <div className='col-lg-6 col-md-6 col-sm-12 mt-5 pt-5'>
          

          <p className='mt-2' style={{color:"#fff", fontSize:'18px', textAlign:'justify'}}>Staking <span  className='ArmorAI10'> ArmorAI </span>tokens unlocks multiple benefits, such as APY earnings, DAO voting power, exclusive <span className='ArmorAI10'> airdrops </span> and additional perks within the ArmorAI ecosystem</p>

          <NavLink to='/SDK'>
            <button className='Stack mt-2 text-rainbow-animation' >Staking Coming Soon</button>
          </NavLink>

        </div>
        
      </div>
    </div>
  </div>
</div>
 
 {/* <Staking/> */}
  
{/* Staking section  end */}



{/* product Vision section start*/}
  <div className='my-5'>
  <Product/>
  
  </div>

{/* product Vision section end*/}
   


   <section>
    <div className='container my-5'>
      <div className='row'> 
        <div className='col-lg-6 col-md-6 col-sm-12  mt-4'>
          <h1 className='text-white Singapore ps-0' style={{lineHeight:'50px'}}>Buy a Presale Worth $10,000 to Get a <span className='Subscribe1'>Vacation in  Singapore/Dubai.</span></h1>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 mb-5 '>
          <img src={dubai} style={{width:'100%', borderRadius:'10px'}}/>
        </div>
      </div>
    </div>
   </section>


   {/* youtube Link start section  */}
   <div className='container'>
    <div className='row'>
      <div className='col-lg-10 m-auto'> 
         <div className='row'>
          <div className='col-lg-12 col-md-12 col-lg-12'>

                   <div className='iframe' style={{margin:'50px 0px 150px 0px',borderRadius:'15px' }}  >
                   <iframe width="100%" height="550px"   src="https://www.youtube.com/embed/nMRxQSy-U58" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{borderRadius:'15px'}} ></iframe>
                   </div>

          </div>
         </div>
        


      </div>
    </div>
   </div>
   {/* youtube Link  end section*/}
 

 {/* Team  section start */}
     {/* <div>
      <Team/>
     </div> */}
 {/* Team  section end */}

   
   {/*Available on section start */}
 
   {/* <div className='container'>
    <div className='row'>
     <div className='col-lg-12 col-sm-12 mt-5'>
      
     <h1 className='text-center text-white'>
       <span className='Available'>
         Soon Available On </span></h1>
     
     </div>
        
     <div className='row m-0'>
      <div className='col-lg-6 col-sm-12 m-auto mt-4'>

      <div className='row'>
       <div className='col-lg-12 col-sm-12 '>
       <div style={{display:'flex', justifyContent:'center'}}>
        <img src={Lkl} alt='img' className='s4 p-2' style={{width:'100%', borderRadius:'15px', }}/>
      </div>
       
       </div>
      </div>
     </div>
     </div> 
    </div>
   </div>  */}
   
   {/* Available on section end */}
 
   {/* Gallery  section start */}

   <section>
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>

           <Gallery/>
        </div>
      </div>
    </div>
   </section>
   {/* Gallery  section end */}








 

   {/*Sponcers and Partners on section start */}

   
   <div className='container'>
    <div className='row'>
     <div className='col-lg-12 col-sm-12 mt-5 pt-5'>
      
     <h1 className='text-center text-white '> <span className='Humanity12  text-rainbow-animation'>Sponsors and Partners </span></h1>
     
     </div> 
    </div>
   </div>


   <section>
   
   <div className='container mt-5'>
    <div className='row'>
    <div className='col-lg-11 m-auto'>
     
    <div className='row mt-3 '> 
     <div className='col-lg-1 col-md-6 col-0 '>   
     </div> 

     <div className='col-lg-2 col-md-6 col-6  mt-2'> 
     <div style={{display:'flex', justifyContent:'center'}}>
    <NavLink to="https://www.binance.com/en"> <img src={S2} alt='img' className=' p-2 Binance  ' style={{width:'100%',  }}/> </NavLink>
     </div>
     </div> 

     <div className='col-lg-2 col-md-6 col-6  mt-2 ' > 
     <div style={{display:'flex', justifyContent:'center'}}> 
    <NavLink to="https://wallet.polygon.technology/"> 
      <img src={S4} alt='img' className='p-2 Binance  gajju'  style={{width:'100%'}}/> 
      </NavLink>
       </div>
     </div>

     <div className='col-lg-2 col-md-6 col-sm-12  mt-2  '> 
     <div style={{display:'flex', justifyContent:'center'}}> 
    <NavLink to="https://www.coinbase.com/    "> <img src={S55} alt='img' className='p-2 coinbase'  style={{width:'100%', }}/></NavLink> 
    </div>
     </div> 

     <div className='col-lg-2 col-md-6 col-6   mt-3'> 
     <div style={{display:'flex', justifyContent:'center'}}>
     <NavLink to="https://www.dexview.com/">  <img src={S10} alt='img' className='  p-2  Binance ' style={{width:'100%'}}/> </NavLink>
     </div>
      
     </div>
   


   
     <div className='col-lg-2 col-md-6 col-6  mt-2   '> 
     <div className=''  style={{display:'flex', justifyContent:'center'}}>
      <NavLink to="https://www.pinksale.finance" style={{textDecoration:'none'}}>
      <h3 style={{display:'flex', alignItems:'center', color:'#fff'}}><img src={S1} alt='img' className='  p-2  Binance ' style={{width:'45%', fontWeight:'600' }}/>PinkSale</h3> </NavLink>
     </div>
      
     </div>
    </div>   
    </div>  
     </div>
   </div>

   </section>
   {/* Sponcers and Partners on section end */}
  
   {/* Multichain Support on section start */}
 
    
   <div className='container'>
   <div className='row'>
    <div className='col-lg-12 col-sm-12 text-center sp '>
     
  <span className='Humanity12  text-rainbow-animation'>  Multichain Support </span> 
    
    </div> 
   </div>
  </div> 


  


  <div className='container'>
   <div className='row'>
    <div className='col-lg-11 m-auto'>
      
     <div className='row'>
      <div className='col-lg-1'></div>

      <div className='col-lg-2 col-6 mt-4 '>
        <div style={{display:'flex', justifyContent:'center'}}>
        <NavLink to="https://arbitrum.io/"> <img src={vv1} alt='Logo' className='vv1 p-1 pt-3' style={{width:'100%'}}/> </NavLink>
        </div>
     </div>

      <div className='col-lg-2 col-6 mt-4 '>
        <div style={{display:'flex', justifyContent:'center'}}>
        <NavLink to="https://chain.link/">  <img src={vv2} alt='Logo'  className='p-2 vv2' style={{width:'100%'}}/> </NavLink>
        </div>
     </div>

      <div className='col-lg-2 col-6 mt-4 '>
        <div style={{display:'flex', justifyContent:'center'}}>
      <NavLink to="https://www.5ire.org/"> <img src={vv3} alt='Logo' className='p-2 vv3' style={{width:'100%'}}/> </NavLink> 
        </div>
     </div>

      <div className='col-lg-2 col-6 mt-4 '>
        <div style={{display:'flex', justifyContent:'center'}}>
        <NavLink to="https://www.optimism.io/"><img src={j6} alt='Logo'  className='p-2 vv3'style={{width:'100%'}}/> </NavLink>
        </div>
     </div>

      <div className='col-lg-2 col-sm-12 mt-3 '>
        <div style={{display:'flex', justifyContent:'center'}}>
        <NavLink to="https://polygon.technology/home"> <img src={j8} alt='Logo'className='p-2  vv5'  style={{width:'100%'}}/></NavLink>
        </div>
     </div>

     </div>

    </div>
   </div>



  
   <div className='row '>
   <div className='col-lg-11 m-auto'>
    
   <div className='row'>
    <div className='col-lg-1'></div>


    

    {/* <div className='col-lg-2 col-6 mt-4'>
    <div style={{display:'flex', justifyContent:'center'}}>
     <img src={j8} alt='img' className='demo p-3 pt-4 gajju' style={{width:'100%'}}/>
    </div>
    </div> */}

    <div className='col-lg-2 col-6 mt-4'>
      <div style={{display:'flex', justifyContent:'center'}}>
      <NavLink to="https://trustwallet.com/">  <img src={j1} alt='img' className='demo mt-4   gajju' style={{width:'100%'}}/></NavLink>
     </div>
    </div> 
    

   <div className='col-lg-2 col-6 mt-4'> 
  <NavLink to="https://sui.io/"> <img src={j20} alt='img' className='demo ' style={{width:'100%'}}/> </NavLink> 
  </div> 
  

   <div className='col-lg-2 col-6 mt-4'> 
   <NavLink to="https://solana.com/"> <img src={j9} alt='img' className=' j9' style={{width:'100%', paddingTop:'40px'}}/>
   </NavLink> 
  </div> 

    <div className='col-lg-2 col-6 mt-4'> 
    <NavLink to="https://www.binance.com/en"> <img src={S2} alt='img' className=' j91 p-3 pt-4' style={{width:'100%',  }}/></NavLink> 
    </div>  

    <div className='col-lg-2 col-sm-12 col-6 mt-4'> 
    <div style={{display:'flex', justifyContent:'center'}}>
   <NavLink to="https://aptoslabs.com/">  <img src={jai} alt='img' className=' j91 p-3 pt-4' style={{width:'100%',}}/> </NavLink> 
    </div>  
    </div>

   </div>

   </div>
  </div>  
  </div>



   {/* Multichain Support on section end */}



     {/* for Attended Events */}


     <div className='container'>
   <div className='row'>
    <div className='col-lg-12 col-sm-12 text-center sp '>
     
  <span className='Humanity12  text-rainbow-animation'>  Attended Events</span> 
    
    </div> 
   </div>
  </div>




  <div className='row '>
   <div className='col-lg-8 m-auto'>
    
   <div className='row'>
    <div className='col-lg-1'></div>
 

    <div className='col-lg-2 col-6 mt-4'> 
    </div> 
    

   <div className='col-lg-2 col-6 mt-3'> 
  <NavLink to="#"> <img src={Attend} alt='img' className='demo p-2' style={{width:'100%'}}/> </NavLink> 
  </div> 
  

   <div className='col-lg-2 col-6 mt-3'> 
   <NavLink to=""> <img src={Attend1} alt='img' className=' j9    ' style={{width:'100%',  }}/>
   </NavLink> 
  </div> 

    <div className='col-lg-2 col-6 mt-'> 
    <NavLink to="#"> <img src={Attend10} alt='img' className=' j91 p-2 ' style={{width:'100%',  }}/></NavLink> 
    </div>  

    <div className='col-lg-2 col-sm-12 col-6 mt-4'> 
    <div style={{display:'flex', justifyContent:'center'}}>
  
    </div>  
    </div>

   </div>

   </div>
  </div> 



{/* end Attended Event */}

    
   <div className='container mt-5 pt-5'>
    <div className='row'>
     <div className='col-lg-12 col-sm-12'>
      
     <h1 className='text-center text-white' > Join The <span className='Subscribe1'> Al Revolution</span></h1>
     <h5 className='text-center text-white mt-4'>Join our community, or Drop us a Message via the Contact form</h5>
     {/* <input type='text' placeholder='jai shree ram' style={{padding:'2px 90px 2px 2px'}}/> */}

      </div>
    </div>
   </div> 





   {/* Career section start*/}

    <div>
     <Career/>
    </div>
   {/* Career section end*/} 
    
 {/* FAQ section start*/} 
<section>
<div className='container'>
 <div className='row'>
  <div className='col-lg-10 m-auto '>
       

  <div className='row'>
   <div className='col-sm-12 col-md-12 col-sm-12 mt-5'>
   

    <h1 className='text-center  text-white'>Some of <span className='Subscribe1'> FAQs</span> are:</h1>
     
    <div>
    
       <h4 className='text-left text-white mt-4' > How can ArmorAI's <span className='Subscribe1'> SDKs </span>and <span className='Subscribe1'> APIs </span> enhance AI safety? </h4> 

       <p className='text-left text-white ' style={{textAlign:'justify'}}> Our SDKs and APIs allow developers to<span className='Subscribe1'>  seamlessly integrate safety </span>measures into their AI applications, ensuring ethical and secure AI systems.</p>


     
    </div>



    <div>
    <h4 className='text-left text-white mt-5'>What is <span className='Subscribe1'> SafeGPT,</span> and how does it ensure safe conversations ?</h4>

    <p className='text-left text-white ' style={{textAlign:'justify'}}>SafeGPT is a chat-based AI tool developed by ArmourAI that enables users to have secure and safe conversations with<span className='Subscribe1'>  AI chatbots,</span> preventing any potentially harmful content.</p>
    
    </div>


    <div>
    <h4 className='text-left text-white mt-5'>What benefits do I get from using<span className='Subscribe1'>  ArmorAI's</span> tools and services?  </h4>

    <p className='text-left text-white ' style={{textAlign:'justify'}}>By using ArmorAI, you can contribute to the responsible and ethical use of AI, <span className=' text-rainbow-animation'> mitigate risks associated with AI</span> applications, and build trustworthy AI systems</p>
    
    </div>

   </div> 

  </div>
  
  
  </div>
 </div>
</div>
</section>

{/* FAQ section end*/}


 
 

<div className='mt-5'>
  <Footer/>
</div>
 


   </div>




   )
}

export default Home






