import React from 'react'
import Header from '../Header/Header'
import rrr from '../../Comp/img/Li.png'
import Footer from '../Footer/Footer'

const Roadmap = () => {
  return (
   
     <>

     <div>
     <Header/>
     </div>
       <section> 
       <div className='container' style={{paddingTop:'120px'}}>
        <div className='row'>
         <div className='col-lg-11 col-md-8 col-sm-12 m-auto'>  
          <div className='row'>    
          <div className='col-lg-12 col-sm-12'>
          
            <h1 className='Roadmap' style={{fontSize:"50px", textAlign:'center', color:'#fff'}}>Our <span className='Subscribe1'> Roadmap </span> to Protect<br/> Humanity from AI</h1>
            


          </div>





    
          </div> 


           
         </div>
        </div>
       </div>


          {/*Desktop view start*/}
         <div className='container Desk mt-5'>



         { /*PHASE 10 start */}

 

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 10</span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2025 Q-1</h5>
 <p>January 1, 2025: Official token launch on leading centralized exchanges (Binance, Bybit, KuCoin, Gate.io, Coinbase, etc.)</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2025 Q-1</h5>
 <p>Launch a comprehensive marketing campaign targeting both developers and investors.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

 

<div className='row   '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2025 Q-1</h5>
 <p>Initiate airdrop campaigns for existing investors to increase engagement and network effect.</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2025 Q-1</h5>
 <p>Deploy the fee-paying model for APIs and SDKs, enabling revenue generation.



</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

 

<div className='row   '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2025 Q-1</h5>
 <p>Onboard additional developers and projects onto the Armor AI network, leveraging the fully developed SDK kit.
 </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
  

</div> 


{ /*PHASE 10 End */}





{ /*PHASE 9 start */}

 

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 9 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-4</h5>
 <p>Conduct the token presale on the Armor Ai platform.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-4</h5>
 <p>Investors can purchase tokens at half the price.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

 

<div className='row   '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-4</h5>
 <p>Finalize preparations for the mainnet launch and initial exchange offerings (IEOs).

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-4</h5>
 <p>Ramp up the marketing campaign targeting both developers and investors.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 


{ /*PHASE 9 End */}




{/* Phase 8 Start */}
<div className='row   my-5'> 
<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 8 </span></button>
  
  </div>
              <div className='col-lg-6 col-md-6 col-sm-12 ' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
               <div className='row ' > 
               
                 <div className='col-lg-3 col-md-6 col-sm-12'>
                </div>
    
                <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
                <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
                <div className='card-body text-white'>  
                  <h5 className='QQ'>2024 Q-4</h5>
                  <p>Officially announce the token presale date, starting in Q4 2024.</p> 
                </div>   
               </div> 
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
                 <div>
                   <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
                 </div>
                </div>
    
               </div>
              </div>
                  
              <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
               <div className='row p-0' style={{marginTop:'100px'}}>  
               
               <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
               <div>
                 <img src={rrr} alt='image' style={{width:'100%'}}/>
               </div>
              </div>
    
    
                <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
                <div className='card mahakal' style={{backgroundColor:'#030614', }}>
                <div className='card-body text-white'>  
                  <h5 className='QQ'>2024 Q-4</h5>
                  <p>Secure and engage investors through targeted marketing and AMAs. Fine-tune the fee-paying model for APIs and SDKs.

</p> 
                </div>   
               </div> 
                </div>
    
               </div>
              </div>
                
             </div> 
      
    
             <div className='row  ' style={{marginTop:'-50px'}}> 
                  
              <div className='col-lg-6 col-md-6 col-sm-12  ' style={{ borderRight:'3px solid #0E152F' , }}>
               <div className='row ' > 
               
                 <div className='col-lg-3 col-md-6 col-sm-12'>
                </div>
    
                <div className='col-lg-6 col-md-6 col-sm-12 p-0 mt-4' >
                <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
                <div className='card-body text-white'>  
                  <h5 className='QQ'>2024 Q-4</h5>
                  <p>Begin final testing of the ecosystem in preparation for the mainnet launch.</p> 
                </div>   
               </div> 
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
                 <div>
                   <img src={rrr} alt='image' className='mt-5 pt-5' style={{width:'100%',  }}/>
                 </div>
                </div>
    
               </div>
              </div>
                   
                
             </div> 
{/* Phase 8 End */}





{ /*PHASE 7 start */}


<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 7 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-2</h5>
 <p>Continue testing and refinement of the system on test networks. </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-2</h5>
 <p>Build strategic partnerships with developers and AI platforms. Further develop cross-chain liquidity and payment visibility solutions.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div>

<div className='row '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-2</h5>
 <p>Leading CEX onbord and Listing. (Binance, Bybit , KUcoin, Gate.io, CoinBase, etc). </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
 

</div> 


{ /*PHASE 7 End */}




{ /*PHASE 6 start */}


<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 6 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Deploy and test the system on a test network with active test nodes.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Intensify marketing efforts targeting developers to build on the Armor AI network.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 


<div className='row '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Conduct AMA campaigns to increase visibility and engage potential investors and users.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Finalize the Armor AI Developer SDK kit.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div>   


{ /*PHASE 6 End */}







{ /*PHASE 5 start */}

 

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 5 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Prepare for the token presale, scheduled for Q4 2024.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Start initial marketing campaigns to build anticipation and awareness within the developer community.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

 

<div className='row   '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Finalize and prepare the Armor AI Developer SDK kit for public release.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
  

</div> 


{ /*PHASE 5 End */}



{ /*PHASE 4 start */}

  

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 4 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Complete development and begin testing of the SDK kit within a developer network.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Recruit developers to participate in testing nodes and the broader testing campaign.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

  

<div className='row '> 

 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Burn 2.5% of the total token supply to ensure token value and scarcity.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Focus on building a stable and secure ecosystem for developers.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 
 

{ /*PHASE 4 end */}




   {/* phase 3 start */}

   <div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 3</span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-2</h5>
 <p>Continue refining the AI-adaptive security system.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-2</h5>
 <p>Launch the alpha stage of the SDK kit development.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

<div className='row'> 

<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
<h5 className='QQ'>2023 Q-2</h5>
<p>Finalize preparations for token creation.</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
<img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>

<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
<h5 className='QQ'>2023 Q-2</h5>
<p>Begin Alpha stage SDK kit Script Creation.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 
 

{/* phase 3 End */}









{/* Phase 2 start */}
<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 2 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-1</h5>
 <p>Deepen understanding of AI algorithms for optimization.

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-1</h5>
 <p>Collecting all leading API's of Ai Systems. Making a system that will adapt to any Ai Systems and make it more secure ecosystem for users.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

<div className='row     '> 


<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
<h5 className='QQ'>2023 Q-1</h5>
<p>Working to make Stable System that will deploy on any AI Algorithm.</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
<img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>

<div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
<h5 className='QQ'>2023 Q-1</h5>
<p>Begin fundraising efforts and advance towards the development of a Developer SDK Kit.

</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div>


<div className='row     '> 


<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
<h5 className='QQ'>2023 Q-1</h5>
<p>AMA Campaign

</p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
<img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>


</div>


{/* phase 2 end */}





          {/* phase 1 start */}
          <div className='row   '> 
                  <div className='my-4' style={{display:'flex', justifyContent:'center'}}>
                  
                  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
                   style={{fontWeight:'500', fontSize:'22px'}}> Phase - 1 </span></button> 
                  </div>
              
          <div className='col-lg-6 col-md-6 col-sm-12 ' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
           <div className='row ' > 
           
             <div className='col-lg-3 col-md-6 col-sm-12'>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
            <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q - 4</h5>
              <p>Initiate research and development on AI algorithms.

</p> 
            </div>   
           </div> 
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
             <div>
               <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
             </div>
            </div>

           </div>
          </div>
              
          <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
           <div className='row p-0' style={{marginTop:'100px'}}>  
           
           <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
           <div>
             <img src={rrr} alt='image' style={{width:'100%'}}/>
           </div>
          </div>


            <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
            <div className='card mahakal' style={{backgroundColor:'#030614', }}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q-4</h5>
              <p>Identify and address bugs that could mislead or harm humans.

</p> 
            </div>   
           </div> 
            </div>

           </div>
          </div>
            
         </div>  

         <div className='row  '> 
              
          <div className='col-lg-6 col-md-6 col-sm-12 ' style={{ borderRight:'3px solid #0E152F' , }}>
           <div className='row ' > 
           
             <div className='col-lg-3 col-md-6 col-sm-12'>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
            <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q-4</h5>
              <p>Begin alpha-stage research focused on optimizing AI systems.</p> 
            </div>   
           </div> 
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
             <div>
               <img src={rrr} alt='image' className='mt-5 pt-5' style={{width:'100%',  }}/>
             </div>
            </div>

           </div>
          </div>



          <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
           <div className='row p-0' style={{marginTop:'100px'}}>  
           
           <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
           <div>
             <img src={rrr} alt='image' style={{width:'100%'}}/>
           </div>
          </div>


            <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
            <div className='card mahakal' style={{backgroundColor:'#030614', }}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q-4</h5>
              <p>Recruit top Data Scientists, Machine Learning Developers, and Blockchain Developers.</p> 
            </div>   
           </div> 
            </div>

           </div>
          </div>
               
            
         </div> 
 
{/* Phase 1 End */}




       
           </div>  
           {/*Desktop view end*/}



           


           {/*mobile view start*/}
                
           <div className='container Mob'>


            
            {/*  Phase 10 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 10 </span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2025 Q-1</h5>
               <p>January 1, 2025: Official token launch on leading centralized exchanges (Binance, Bybit, KuCoin, Gate.io, Coinbase, etc.).

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2025 Q-1</h5>
               <p>Launch a comprehensive marketing campaign targeting both developers and investors.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2025 Q-1</h5>
               <p>Initiate airdrop campaigns for existing investors to increase engagement and network effect.

</p> 
             </div>   
            </div>  
             </div>


            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2025 Q-1</h5>
               <p>Deploy the fee-paying model for APIs and SDKs, enabling revenue generation.

</p> 
             </div>   
            </div>  
             </div>
          
             
            </div>






            <div className='row p-3'>
            
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
            <div className='card mahakal mt-2 ' style={{backgroundColor:'#030614',width:'100%'}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2025 Q-1</h5>
              <p>Onboard additional developers and projects onto the Armor AI network, leveraging the fully developed SDK kit.

</p> 
            </div>   
           </div>  
            </div>
            </div>
            {/*  Phase 10 end */}

            
            {/*  Phase 9 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 9</span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-4</h5>
               <p>Conduct the token presale on the Armor Ai platform.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-4</h5>
               <p>Investors can purchase tokens at half the price.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-4</h5>
               <p>Finalize preparations for the mainnet launch and initial exchange offerings (IEOs).



</p> 
             </div>   
            </div>  
             </div>


            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-4</h5>
               <p>Ramp up the marketing campaign targeting both developers and investors.



</p> 
             </div>   
            </div>  
             </div>
          
             
            </div>
 
            {/*  Phase 9 end */}







            
            {/*  Phase 8 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 8</span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-3</h5>
               <p>Officially announce the token presale date, starting in Q4 2024.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-3</h5>
               <p>Secure and engage investors through targeted marketing and AMAs. Fine-tune the fee-paying model for APIs and SDKs.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-3</h5>
               <p>Begin final testing of the ecosystem in preparation for the mainnet launch.</p> 
             </div>   
            </div>  
             </div>


            
          
             
            </div>
 
            {/*  Phase 8 end */}






            
            {/*  Phase 7 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 7</span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-2</h5>
               <p>Continue testing and refinement of the system on test networks.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-2</h5>
               <p>Build strategic partnerships with developers and AI platforms. Further develop cross-chain liquidity and payment visibility solutions.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-2</h5>
               <p>Prepare for presale activities by enhancing the marketing campaign and creating presale materials.</p> 
             </div>   
            </div>  
             </div>


            
          
             
            </div>
 
            {/*  Phase 7 end */}



 {/*  Phase 6 start */}

 <div className='row p-3 '>
            <div className='my-1' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 6 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Deploy and test the system on a test network with active test nodes.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Intensify marketing efforts targeting developers to build on the Armor AI network.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Conduct AMA campaigns to increase visibility and engage potential investors and users.

</p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>
 
            <div className='row p-3 '>
           
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-2 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Finalize the Armor AI Developer SDK kit.</p> 
             </div>   
            </div>  
             </div>
           
            


       
          
             
            </div> 

            {/*  Phase 6 end */}






            {/*  Phase 5 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}>Phase - 5 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Prepare for the token presale, scheduled for Q4 2024.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Start initial marketing campaigns to build anticipation and awareness within the developer community.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Finalize and prepare the Armor AI Developer SDK kit for public release.</p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div> 
              

            {/*  Phase 5 end */} 



            


            {/*  Phase 4 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 4 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-3</h5>
               <p>Complete development and begin testing of the SDK kit within a developer network.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Recruit developers to participate in testing nodes and the broader testing campaign.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Burn 2.5% of the total token supply to ensure token value and scarcity.

</p> 
             </div>   
            </div>  
             </div>



            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Focus on building a stable and secure ecosystem for developers.</p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div> 
            {/*  Phase 4 end */}





            
            {/*  Phase 3 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}>Phase - 3 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Continue refining the AI-adaptive security system.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Launch the alpha stage of the SDK kit development.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Finalize preparations for token creation.

</p> 
             </div>   
            </div>  
             </div>

          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Begin Alpha stage SDK kit Script Creation.</p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>
 
            {/*  Phase 3 end */}






   {/*  Phase 2 start */}

   <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 2 </span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Deepen understanding of AI algorithms for optimization.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Collecting all leading API's of Ai Systems. Making a system that will adapt to any Ai Systems and make it more secure ecosystem for users.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Working to make Stable System that will deploy on any AI Algorithm.

</p> 
             </div>   
            </div>  
             </div>


            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Begin fundraising efforts and advance towards the development of a Developer SDK Kit.

</p> 
             </div>   
            </div>  
             </div>
          
             
            </div> 

            <div className='row p-3'>
            
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
            <div className='card mahakal mt-2 ' style={{backgroundColor:'#030614',width:'100%'}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q-1</h5>
              <p>AMA Campaign</p> 
            </div>   
           </div>  
            </div>
            </div>
            {/*  Phase 2 end */}





    {/*  Phase 1  start */}
            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 1 </span></button>
  
  </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Initiate research and development on AI algorithms.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Identify and address bugs that could mislead or harm humans.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Begin alpha-stage research focused on optimizing AI systems.

</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Recruit top Data Scientists, Machine Learning Developers, and Blockchain Developers.



</p> 
             </div>   
            </div>  
             </div>
          
             
            </div>

    {/*  Phase 1 end */}


         

































           


           </div>

           {/*mobile view end*/}
       </section>



       <div className='mt-5'>
        
       <Footer/>
        
        </div>
     
    </>
  )
}

export default Roadmap;